exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage":
      return "/"
    case "about_us":
      return "/about"
    case "careers":
      return "/careers"
    case "vehicle_moves":
      return `/${doc.uid}`
    case "general":
      return `/${doc.uid}`
    case "faq":
      return "/faq"
    default:
      // Sensible fallback
      return "/"
  }
}
