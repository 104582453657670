import "focus-visible"
import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"

const repositoryConfigs = [
  {
    repositoryName:
      process.env.GATSBY_PRISMIC_REPO_NAME || process.env.PRISMIC_REPO_NAME,
    accessToken:
      process.env.GATSBY_PRISMIC_ACCESS_TOKEN ||
      process.env.PRISMIC_ACCESS_TOKEN,
    linkResolver: require("./src/utils/linkResolver").linkResolver,
    componentResolver: [
      {
        vehicle_moves: React.lazy(
          () => import("./src/templates/vehicleMoves.js"),
        ),
        general: React.lazy(() => import("./src/templates/general.js")),
        careers: React.lazy(() => import("./src/pages/careers.js")),
        about_us: React.lazy(() => import("./src/pages/about.js")),
        homepage: React.lazy(() => import("./src/pages/index.js")),
      },
    ],
  },
]

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
)
