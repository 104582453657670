module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PZFWNJDW","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"unitedroadwebsite","accessToken":"MC5YbjBpeGhBQUFCNEFkNGhH.Ku-_ve-_ve-_vQpJNu-_vSjvv73vv71v77-977-9XVrvv73vv71zWe-_ve-_ve-_ve-_vT5K77-977-977-977-9UWM","routes":[{"type":"vehicle_moves","path":"/:uid/unpublished"},{"type":"general","path":"/:uid/unpublished"},{"type":"careers","path":"/careers/unpublished"},{"type":"about_us","path":"/about/unpublished"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7cc26834f7fbd356ec6139311b11bb10"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
